import { useEffect, useState, useCallback, useRef } from "react";

import { Box, Drawer, Divider, IconButton, CardMedia, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { HttpClientApi, SetProdClickParams } from "../../../../components/interface/client-rest-api";
import { CommonUtils } from "../../../Utils/common_utils";
import { useInterval } from "../../../Utils/UseInterval";

import "./LiveProduct.css";

/*
2024년 7월 4일 리바트 이현영 선임 요청
상담신청 방송일 경우 (어드민 내 상담 배너 이미지 등록 여부로 판단)
> 접혀있을 때 : 
상품 없는 경우 : 상담신청 배너만 노출 
상품 있는 경우 : 기존처럼 상품 롤링 (상담배너 노출 없음)
> 펼쳐졌을때 : 상담신청 > 이미지배너 > 상품 순으로 노출
 
상담신청 방송 아닌 경우
> 접혀있을 때 : 상품 순위별 노출
> 펼쳤을때 : 이미지배너 > 상품 순으로 노출
 */

interface propsType {
  hidden_menu: boolean;
  prodList: any;
  postMsg: any;
  isNotFloat: any;
  isFloat: any;
  broadSeq: any;
  userId: any;
  videoMode: number;
  hostId?: any; // 컨설팅 링크 구분용
  liveProducts: any;
  cnslImgPath: string; // 리바트 상담신청 배너 이미지
  cnslPageUrl: string; // 리바트 상담신청 배너 랜딩 URL
  bnrImgPath: string; // 리바트 이미지배너 이미지
  bnrPageUrl: string; // 리바트 이미지배너 랜딩 URL
  loadChannelInfoOnly: any;
}

const cUtils = new CommonUtils();

const LiveProduct = (props: propsType) => {
  const ClientApi = new HttpClientApi();
  const prodCircle = useRef<HTMLDivElement>(null);

  const [openProd, setOpenProd] = useState(false);
  const [prodList, setProdList] = useState([] as any);
  const [prodPhotoRender, setProdPhotoRender] = useState([] as any);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // 상품 로드
    LoadProdList();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenProd(false);
      setProdList([] as any);
      setProdPhotoRender([] as any);
      setCurrentIndex(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tempNow: any = [];
    let tempNotNow: any = [];
    if (props.prodList.length > 0) {
      if (props.prodList[0].goodsNm !== undefined) {
        for (const prod of props.prodList) {
          if (props.liveProducts.indexOf(prod.goodsSn) > -1) {
            tempNow.push({
              name: prod.goodsNm,
              soldout: prod.goodsStCd === "03", // 상품 품절 시 true 반환
              salePrice: prod.salePrc,
              salePer: prod.salePer,
              thumbImageUrl: prod.goodsImgPath,
              productId: prod.goodsSn,
              goodsPageUrl: prod.goodsPageUrl,
              consumPrc: prod.consumPrc,
            });
          } else {
            tempNotNow.push({
              name: prod.goodsNm,
              soldout: prod.goodsStCd === "03", // 상품 품절 시 true 반환
              salePrice: prod.salePrc,
              salePer: prod.salePer,
              thumbImageUrl: prod.goodsImgPath,
              productId: prod.goodsSn,
              goodsPageUrl: prod.goodsPageUrl,
              consumPrc: prod.consumPrc,
            });
          }
        }

        setProdList([...tempNow, ...tempNotNow]);
      }
    }
  }, [props.prodList, props.liveProducts]);

  // 상품 정보 불러오기
  const LoadProdList = () => {
    // 부모 프레임에 메시지 전달
    // 지금은 모두로 보내지만
    // 나중에 더현대 주소로 변경해야함
    console.log("SEND LoadProdList");
    // props.postMsg({ msg: "LoadProdList" }, "*");
    props.loadChannelInfoOnly();
  };

  const rollingProduct = () => {
    if (!prodCircle.current) {
      return;
    }
    prodCircle.current.style.transition = `all 3s ease-in-out`;
    prodCircle.current.style.transform = `translateY(-${currentIndex}00%)`;

    if (currentIndex === prodList.length) {
      setTimeout(() => {
        if (!prodCircle.current) {
          return;
        }
        prodCircle.current.style.transition = `0s`;
        prodCircle.current.style.transform = `translateY(0)`;
      }, 3001);
      setCurrentIndex(1);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  let delayPool: number = 6000;
  useInterval(
    () => {
      rollingProduct();
    },
    prodList.length > 0 ? delayPool : null
  );

  // 상품 원 모양 버튼 이미지 슬라이드 HTML Element 설정
  const handleProdCicle = useCallback(() => {
    // console.log("handleProdCicle: 상품 출력", prodList);
    if (prodList.length > 0) {
      setProdPhotoRender(
        //props.broad_prod_list
        prodList
          .map((obj: any, index: number) => {
            return (
              <div className="slideItem" key={"key" + index} onClick={() => clickProd(obj)}>
                <img className="item-thumb" src={obj.thumbImageUrl} alt="" />
                <div className="item-desc">
                  <div className="item-title">
                    {props.liveProducts.indexOf(obj.productId) > -1 && <span className="live-product-rolling-now">NOW</span>}
                    {obj.name}
                  </div>
                  <Stack direction="row" display="flex" alignItems="center" spacing={0.5}>
                    {obj.salePer !== undefined && obj.salePer !== null && obj.salePer > 0 && (
                      <span className="color-red sale-price">{obj.salePer + "%"}</span>
                    )}
                    <div className="item-price">{cUtils.numericComma(obj.salePrice) + "원"}</div>
                  </Stack>
                </div>
              </div>
            );
          })
          .concat(
            <div className="slideItem" key={"key_" + prodList[0].productId} onClick={() => clickProd(prodList[0])}>
              <img className="item-thumb" src={prodList[0].thumbImageUrl} alt="" />
              <div className="item-desc">
                <div className="item-title">
                  {props.liveProducts.indexOf(prodList[0].productId) > -1 && <span className="live-product-rolling-now">NOW</span>}
                  {prodList[0].name}
                </div>
                <Stack direction="row" display="flex" alignItems="center" spacing={0.5}>
                  {prodList[0].salePer !== undefined && prodList[0].salePer !== null && prodList[0].salePer > 0 && (
                    <span className="color-red sale-price">{prodList[0].salePer + "%"}</span>
                  )}
                  <div className="item-price">{cUtils.numericComma(prodList[0].salePrice) + "원"}</div>
                </Stack>
              </div>
            </div>
          )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodList, props.liveProducts]);

  useEffect(() => {
    // 상품 사진 슬라이드
    handleProdCicle();
  }, [handleProdCicle]);

  const handleProdDrawerClose = () => {
    setOpenProd(false);
    props.isFloat();
  };
  const handleProdDrawerOpen = () => {
    setOpenProd(true);
  };

  const handleProdOpen = () => {
    // 품절 상태 확인하기 위해 다시 상품 리스트 호출
    LoadProdList();
    // floating 막기
    props.isNotFloat();
    // Drawer 오픈 함수
    handleProdDrawerOpen();
  };

  const clickProd = async (prod: any) => {
    handleProdDrawerClose();
    window.open(prod.goodsPageUrl, "_blank");
    try {
      let param: SetProdClickParams = {
        broad_seq: props.broadSeq,
        user_id: props.userId,
        prod_id: prod.productId,
        prod_name: prod.name,
        prod_price: prod.salePrice,
      };

      await ClientApi.set_prod_click(param).then((res) => {
        // console.log(`상품 클릭 이벤트 : ${res}`);
      });
    } catch (e) {
      console.error("set_prod_click ERROR : ", e);
    }
  };

  return (
    <>
      {(prodList.length > 0 || props.cnslImgPath !== "") && (
        <Box component="div" sx={{ width: "100%" }} className="color-snow">
          <Box sx={{ p: "8px 14px" }}>
            <Stack
              direction="row"
              spacing={0}
              sx={{
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                // marginBottom: `${props.hidden_menu && props.chat_open ? "41px" : "0"}`
              }}
            >
              <Box
                sx={
                  props.hidden_menu
                    ? { display: "none" }
                    : prodList.length === 0 && props.cnslImgPath === ""
                    ? { display: "none" }
                    : { display: "flex", justifyContent: "space-between" }
                }
                className="prod-circle-button-box white-shadow"
              >
                {prodList.length > 0 ? (
                  //상품 리스트 롤링
                  <>
                    <Box className="prod-circle-button ">
                      <div className="slider-circle" ref={prodCircle}>
                        {prodPhotoRender}
                      </div>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }} onClick={handleProdOpen}>
                      <Box sx={{ border: "0.3px solid #DDDDDD", height: "45px" }}></Box>
                      <Box className="prod-circle-cnt">
                        <span>{prodList.length}</span>
                        <span style={{ fontSize: "11px" }}>더보기</span>
                      </Box>
                    </Box>
                  </>
                ) : props.cnslImgPath !== "" ? (
                  //  상담신청 배너
                  <CardMedia
                    component="img"
                    image={props.cnslImgPath}
                    sx={{ height: "70px", objectFit: "cover", borderRadius: "7px" }}
                    onClick={handleProdOpen}
                  />
                ) : (
                  <></>
                )}
              </Box>
              <Drawer
                PaperProps={
                  props.videoMode === 1
                    ? {
                        elevation: 0,
                        style: {
                          borderTopRightRadius: "20px",
                          borderTopLeftRadius: "20px",
                        },
                        sx: {
                          ...{
                            width: "100%",
                            maxWidth: "56.25vh",
                            left: "50%",
                            transform: "translateX(calc(-50%)) !important",
                            overflow: "hidden",
                          },
                        },
                      }
                    : {
                        elevation: 0,
                        style: {
                          borderTopRightRadius: "20px",
                          borderTopLeftRadius: "20px",
                        },
                      }
                }
                anchor="bottom"
                open={openProd}
                onClose={handleProdDrawerClose}
                className="live-more-body"
              >
                <Box className="live-more-body-box">
                  <Box className="prod-list-header">
                    <Box className="prod-list-header-title">
                      <img src="/images/modal-shoplist.png" style={{ width: "20px", marginRight: "7px", marginTop: "2px" }} alt="" />{" "}
                      판매상품 <span>{prodList.length}</span>
                    </Box>
                    <IconButton sx={{ padding: 0 }} onClick={handleProdDrawerClose}>
                      <CloseIcon sx={{ fontSize: "30px", color: "#BBBBBB" }} />
                    </IconButton>
                  </Box>
                  <Box sx={{ width: "100%", mt: 1 }} className={props.videoMode === 1 ? "prod-scroll" : ""}>
                    <nav aria-label="Set Resolution">
                      <div className="prod-list">
                        {/* 상담신청 배너 */}
                        {props.cnslImgPath !== "" && props.cnslImgPath !== null && props.cnslImgPath !== undefined && (
                          <Box
                            className="prod-item-box"
                            onClick={() => {
                              if (props.cnslPageUrl !== "") {
                                handleProdDrawerClose();
                                window.open(props.cnslPageUrl, "_blank");
                              }
                            }}
                            sx={{ overflow: "hidden" }}
                          >
                            <Box className="prod-item-info">
                              <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <CardMedia
                                  component="img"
                                  image={props.cnslImgPath}
                                  sx={{ height: "70px", objectFit: "cover", borderRadius: "7px" }}
                                />
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        )}
                        {/* 이미지 배너 */}
                        {props.bnrImgPath !== "" && props.bnrImgPath !== null && props.bnrImgPath !== undefined && (
                          <Box
                            className="prod-item-box"
                            onClick={() => {
                              if (props.bnrPageUrl !== "") {
                                handleProdDrawerClose();
                                window.open(props.bnrPageUrl, "_blank");
                              }
                            }}
                            sx={{ overflow: "hidden" }}
                          >
                            <Box className="prod-item-info">
                              <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <CardMedia
                                  component="img"
                                  image={props.bnrImgPath}
                                  sx={{ height: "70px", objectFit: "cover", borderRadius: "7px" }}
                                />
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        )}
                        {/* 상품목록 */}
                        {prodList.map((obj: any, index: number) => (
                          <div key={`prod${index}`}>
                            <Box
                              className="prod-item-box"
                              onClick={() => clickProd(obj)} // 품절이어도 상품 클릭은 됨
                            >
                              <Box className="prod-item-info" sx={{ filter: obj.soldout ? "opacity(0.5)" : "none" }}>
                                <Box className="prod-image-box">
                                  <img src={obj.thumbImageUrl} alt="" />
                                  {props.liveProducts.indexOf(obj.productId) > -1 ? (
                                    <>
                                      <div className="live-product-now">NOW</div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                                <Box className="prod-desc-box">
                                  <Box className="prod-desc-title-box">
                                    <div className="prod-desc-title-2line">{obj.name}</div>
                                  </Box>
                                  <div>
                                    {obj.soldout ? (
                                      "품절"
                                    ) : obj.consumPrc === obj.salePrice ? (
                                      <span className="sale-price">{cUtils.numericComma(obj.salePrice) + "원"}</span>
                                    ) : (
                                      <Stack direction="column" spacing={-0.7} mt={0.3}>
                                        {obj.consumPrc !== undefined && obj.consumPrc !== null && (
                                          <span className="original-price line-through color-lightgray">{`${cUtils.numericComma(
                                            obj.consumPrc
                                          )}원`}</span>
                                        )}
                                        <Stack direction="row" display="flex" alignItems="center" spacing={0.5}>
                                          {obj.salePer !== undefined && obj.salePer !== null && obj.salePer > 0 && (
                                            <span className="color-red sale-price">{obj.salePer + "%"}</span>
                                          )}
                                          <span className="sale-price">{`${cUtils.numericComma(obj.salePrice)}원`}</span>
                                        </Stack>
                                      </Stack>
                                    )}
                                  </div>
                                </Box>
                              </Box>
                              <Divider />
                            </Box>
                          </div>
                        ))}
                      </div>
                    </nav>
                  </Box>
                </Box>
              </Drawer>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LiveProduct;
