import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { HttpClientApi } from "../../../../components/interface/client-rest-api";

import { userState } from "../../../interface/MainInterface";
import { useInterval } from "../../../Utils/UseInterval";
import { androidWebAppInterface } from "../../../interface/MainInterface";
import "./LiveCoupon.css";

interface propsType {
  broad_seq: string;
  user_id: string;
  cust_no: string;
  userState: userState;
  coupons: any;
  postMsg: any;
  goLoginPage: any;
  hidden_menu: boolean;
}

declare var android: androidWebAppInterface;

const clientApi = new HttpClientApi(); // API

const LiveCouponAndroid = (props: propsType) => {
  const priceFormatter = new Intl.NumberFormat("ko");

  const [delay, setDelay] = useState(0);
  const [joinCoupon, setJoinCoupon] = useState<any>(null);
  const [durationCoupon, setDurationCoupon] = useState<any>(null);
  const [deleteCoupon, setDeleteCoupon] = useState(true);

  // const [livartCoupon, setLivartCoupon] = useState([] as any);
  const [isLogin, setIsLogin] = useState(false);
  // const [isCouponHidden, setIsCouponHidden] = useState(true);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setDelay(0);
      setJoinCoupon(null);
      setDurationCoupon(null);
      setDeleteCoupon(true);
      // setLivartCoupon([]);
      setIsLogin(false);
      // setIsCouponHidden(true);
    };
  }, []);

  // useEffect(() => {
  //   console.log("리바트 쿠폰: ", livartCoupon);
  //   if(livartCoupon.length > 0) { // 쿠폰이 있는지 확인
  //     console.log("리바트 쿠폰: ", livartCoupon[0]);
  //     if (Number(livartCoupon[0].mbrMaxIssuQty) < Number(livartCoupon[0].mbrCpnDnldCnt)) {
  //       setIsCouponHidden(true); // 고객이 최대 발급량을 넘기면 쿠폰 비노출
  //     } else {
  //       setIsCouponHidden(false);
  //     }
  //   } else {
  //     setIsCouponHidden(true); // 쿠폰이 없으므로 쿠폰 비노출
  //   }
  // }, [livartCoupon]);

  useEffect(() => {
    getCoupon();
    if (props.user_id !== "" && props.user_id !== undefined) {
      // 회원일 때
      setIsLogin(true);
    }
  }, [props.user_id]);

  const existMyCoupon = (coupons: any, type: string) => {
    for (const coupon of coupons) {
      if (coupon.coupon_type === type) {
        return true;
      }
    }
    return false;
  };

  const procCoupons = async (coupons: any) => {
    // itne db에 현재 로그인한 사용자의 쿠폰 다운로드 이력을 조회
    let my_coupon_list: any = [];
    if (props.cust_no !== "") {
      const param: any = {
        broad_seq: props.broad_seq,
        cust_no: props.cust_no,
      };
      const res = await clientApi.get_broad_my_coupon_list(param);
      if ("" + res.code === "200") {
        my_coupon_list = res.response.my_coupon_list;
      }
    }

    for (const coupon of coupons) {
      if (coupon.liveCopnType === "join") {
        if (props.user_id !== "" && props.user_id !== undefined) {
          // 쿠폰 다운로드 이력이 없으면 로그인 쿠폰 다운로드 호출
          if (!existMyCoupon(my_coupon_list, coupon.liveCopnType)) downloadCoupon(coupon);
        } else {
          viewJoinCoupon(coupon);
        }
      } else if (coupon.liveCopnType === "duration") {
        setDelay(coupon.durationTime);
        if (props.user_id !== "" && props.user_id !== undefined) {
          // 쿠폰 다운로드 이력이 없으면 쿠폰 노출
          if (!existMyCoupon(my_coupon_list, coupon.liveCopnType)) viewDurationCoupon(coupon);
        }
      }
    }
  };

  // useEffect(() => {
  //   setLivartCoupon(props.coupons.map((obj: any, index: number) => {
  //     return {
  //       id: obj.cpnSn, // 쿠폰 일련번호
  //       name: obj.cpnNm, // 쿠폰 이름
  //       type: obj.cpnAplyMethCd, // "10": 정액, "20": 정률
  //       price: obj.cpnAplyVal, // 쿠폰 금액
  //       mbrMaxIssuQty: obj.mbrMaxIssuQty, // 회원별 최대 발급 가능 수량
  //       mbrCpnDnldCnt: obj.mbrCpnDnldCnt // 회원별 현재까지 발급한 쿠폰 수량
  //     };
  //   }));
  //   // procCoupons(props.coupons);
  // }, [props.coupons]);

  const viewJoinCoupon = (coupon: any) => {
    setJoinCoupon(coupon);
  };

  const viewDurationCoupon = (coupon: any) => {
    setDurationCoupon(coupon);
  };

  const getCoupon = () => {
    console.log("SEND getCoupon");
    try {
      android.getCouponList();
    } catch (error) {
      console.error(error);
    }
  };

  const downloadCoupon = async (coupon: any) => {
    console.log("SEND downloadCoupon");
    try {
      // 쿠폰 다운로드 요청정보를 저장
      const param: any = {
        broad_seq: props.broad_seq,
        cust_no: props.cust_no,
        coupon_type: coupon.liveCopnType,
      };
      await clientApi.put_broad_my_coupon_list(param);

      // Android 쿠폰 다운로드 호출
      android.downloadCoupon();
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogin = () => {
    // 로그인 호출
    props.goLoginPage();
  };

  useEffect(() => {
    if (durationCoupon !== null) {
      if (delay > 0) {
        setDeleteCoupon(false);
      }
    }
  }, [durationCoupon]);

  const interval = 1000;
  useInterval(
    () => {
      if (delay <= 0) {
        downloadCoupon(durationCoupon);
        setDeleteCoupon((deleteCoupon) => true);
      }
      setDelay((delay) => delay - 1);
    },
    deleteCoupon ? null : interval
  );

  const minuteConverter = function (inputSec: number) {
    const min = (inputSec % 3600) / 60 < 10 ? "0" + Math.floor((inputSec % 3600) / 60) : Math.floor((inputSec % 3600) / 60);
    const sec = inputSec % 60 < 10 ? "0" + Math.floor(inputSec % 60) : Math.floor(inputSec % 60);
    return `${min}:${sec}`;
  };

  return (
    <>
      {/* {(joinCoupon === null && durationCoupon === null) || (durationCoupon !== null && deleteCoupon === true) ? ( */}
      {/* {isCouponHidden ? 
      (
        <></>
      ) : ( */}
      <Box
        className="live-coupon-img-root"
        sx={props.hidden_menu ? { display: "none" } : { display: "flex", zIndex: "1000" }}
        // onClick={joinCoupon !== null ? handleLogin : undefined}
        onClick={isLogin ? downloadCoupon : handleLogin}
      >
        <Box className="live-coupon-img-container" sx={{}}>
          <Box className="live-coupon-price">
            <Typography fontFamily="Noto Sans KR" fontSize="14px" lineHeight="20px" fontStyle="normal" fontWeight="700">
              {/* 30,000원 */}
              {/* {joinCoupon !== null ? (
                  joinCoupon.famtFxrtGbcd === "2" ? (
                    priceFormatter.format(joinCoupon.famtFxrtVal.replaceAll(" ", "")) + "원"
                  ) : (
                    joinCoupon.famtFxrtVal.replaceAll(" ", "") + "%"
                  )
                ) : durationCoupon !== null ? (
                  durationCoupon.famtFxrtGbcd === "2" ? (
                    priceFormatter.format(durationCoupon.famtFxrtVal.replaceAll(" ", "")) + "원"
                  ) : (
                    durationCoupon.famtFxrtVal.replaceAll(" ", "") + "%"
                  )
                ) : (
                  <></>
                )} */}
              {/* <>{livartCoupon[0].price}{livartCoupon[0].type === "10" ? "원": "%"}</> */}
              <>
                {props.coupons[0].cpnAplyVal}
                {props.coupons[0].cpnAplyMethCd === "10" ? "원" : "%"}
              </>
            </Typography>
          </Box>
        </Box>
        <Box className="live-coupon-login-container">
          <Typography textAlign="center" fontFamily="Roboto" fontSize="12px" lineHeight="14px" fontStyle="normal" fontWeight="700">
            {/* {Counter(120)} */}
            {/* {joinCoupon !== null ? (
                <>로그인하고 혜택받기</>
              ) : durationCoupon !== null ? (
                <>
                  {minuteConverter(delay)} 후 <br />
                  쿠폰 지급
                </>
              ) : (
                <></>
              )} */}
            {isLogin ? (
              <>
                클릭하고
                <br />
                혜택받기
              </>
            ) : (
              <>로그인하고 혜택받기</>
            )}
          </Typography>
        </Box>
      </Box>
      {/* )} */}
    </>
  );
};

export default LiveCouponAndroid;
