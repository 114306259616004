import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import "./CouponComplete.css";

interface propsType {}

const CouponComplete = (props: propsType, ref: any) => {
  const [openCouponComplete, setOpenCouponComplete] = useState(false);

  const handleCouponCompleteModalClose = () => setOpenCouponComplete(false);
  const handleCouponCompleteModalOpen = () => setOpenCouponComplete(true);
  const open = () => {
    handleCouponCompleteModalOpen();
  };

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenCouponComplete(false);
    };
  }, []);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openCouponComplete}
        onClose={handleCouponCompleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="coupon-complete-modal">
          {/* <Box className="modal-top-border">
            <span className="green-border"></span>
            <span className="pink-border"></span>
          </Box> */}
          <Box className="coupon-complete-modal-content">
            <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
              <Box component="img" className="coupon-complete-icon" src="/images/icon-download-coupon.png" />
              <Typography
                sx={{ marginLeft: "5px" }}
                className="coupon-complete-text"
                fontFamily="Noto Sans KR"
                fontWeight="700"
                fontSize="14px"
                fontStyle="normal"
              >
                쿠폰 다운로드 완료
              </Typography>
              <div className="coupon-complete-header-blank" />
              <IconButton sx={{ position: "relative", left: "12px" }} onClick={handleCouponCompleteModalClose}>
                <CloseIcon sx={{ fontSize: "20px" }} />
              </IconButton>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0.5}
              sx={{ width: "100%", height: "100%", marginTop: "15px" }}
            >
              <Box component="img" className="coupon-complete-check-icon" src="/images/icon-check.png" />
              <Typography className="coupon-complete-text" fontFamily="Noto Sans KR" fontWeight="400" fontSize="11px" fontStyle="normal">
                LIVE 할인 쿠폰이 정상 발급 되었습니다.
              </Typography>
              <div className="coupon-complete-content-blank" />
              <Button
                sx={{
                  color: "#FF376D",
                  width: "100%",
                  border: 1,
                  borderRadius: "10px",
                  borderColor: "#FF376D",
                }}
                onClick={handleCouponCompleteModalClose}
              >
                <Typography className="coupon-complete-text" fontFamily="Noto Sans KR" fontWeight="700" fontSize="11px" fontStyle="normal">
                  확인
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default forwardRef(CouponComplete);
