import { useEffect } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import "./LiveShowLogin.css";

interface propsType {
  user_id: string;
  goLoginPage: any;
  hidden_menu: boolean;
}

const LiveShowLogin = (props: propsType) => {
  useEffect(() => {
    // console.log("여기");
  }, []);

  const handleLogin = () => {
    // 로그인 호출
    props.goLoginPage();
  };

  return (
    <>
      <Box
        className="live-show-login-img-root"
        sx={props.hidden_menu ? { display: "none" } : { display: "flex", zIndex: "1000" }}
        onClick={handleLogin}
      >
        <Box className="live-show-login-container">
          <Typography textAlign="center" fontFamily="Roboto" fontSize="12px" lineHeight="14px" fontStyle="normal" fontWeight="700">
            <>로그인하고 혜택받기</>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default LiveShowLogin;
