import { useEffect, useState } from "react";

import { Box, CardMedia, Stack, Divider } from "@mui/material";

import { HttpClientApi, SetProdClickParams } from "../../../../components/interface/client-rest-api";
import { CommonUtils } from "../../../Utils/common_utils";

import "./LiveProduct.css";

/*
2024년 7월 4일 리바트 이현영 선임 요청
상담신청 방송일 경우 (어드민 내 상담 배너 이미지 등록 여부로 판단)
> 접혀있을 때 : 
상품 없는 경우 : 상담신청 배너만 노출 
상품 있는 경우 : 기존처럼 상품 롤링 (상담배너 노출 없음)
> 펼쳐졌을때 : 상담신청 > 이미지배너 > 상품 순으로 노출
 
상담신청 방송 아닌 경우
> 접혀있을 때 : 상품 순위별 노출
> 펼쳤을때 : 이미지배너 > 상품 순으로 노출
 */

interface propsType {
  hidden_menu: boolean;
  prodList: any;
  postMsg: any;
  isNotFloat: any;
  isFloat: any;
  broadSeq: any;
  userId: any;
  videoMode: number;
  hostId: any; // 컨설팅 링크 구분용
  liveProducts: any;
  cnslImgPath: string; // 리바트 상담신청 배너 이미지
  cnslPageUrl: string; // 리바트 상담신청 배너 랜딩 URL
  bnrImgPath: string; // 리바트 이미지배너 이미지
  bnrPageUrl: string; // 리바트 이미지배너 랜딩 URL
}

const cUtils = new CommonUtils();

const LiveProductPc = (props: propsType) => {
  const ClientApi = new HttpClientApi();
  const [prodList, setProdList] = useState([] as any);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setProdList([] as any);
    };
  }, []);

  useEffect(() => {
    let tempNow: any = [];
    let tempNotNow: any = [];
    if (props.prodList.length > 0) {
      if (props.prodList[0].goodsNm !== undefined) {
        for (const prod of props.prodList) {
          if (props.liveProducts.indexOf(prod.goodsSn) > -1) {
            tempNow.push({
              name: prod.goodsNm,
              soldout: prod.goodsStCd === "03", // 상품 품절 시 true 반환
              salePrice: prod.salePrc,
              salePer: prod.salePer,
              thumbImageUrl: prod.goodsImgPath,
              productId: prod.goodsSn,
              goodsPageUrl: prod.goodsPageUrl,
              consumPrc: prod.consumPrc,
            });
          } else {
            tempNotNow.push({
              name: prod.goodsNm,
              soldout: prod.goodsStCd === "03", // 상품 품절 시 true 반환
              salePrice: prod.salePrc,
              salePer: prod.salePer,
              thumbImageUrl: prod.goodsImgPath,
              productId: prod.goodsSn,
              goodsPageUrl: prod.goodsPageUrl,
              consumPrc: prod.consumPrc,
            });
          }
        }

        setProdList([...tempNow, ...tempNotNow]);
      }
    }
  }, [props.prodList, props.liveProducts]);

  const clickProd = async (prod: any) => {
    window.open(prod.goodsPageUrl, "_blank");
    try {
      let param: SetProdClickParams = {
        broad_seq: props.broadSeq,
        user_id: props.userId,
        prod_id: prod.productId,
        prod_name: prod.name,
        prod_price: prod.salePrice,
      };

      await ClientApi.set_prod_click(param).then((res) => {
        // console.log("상품 클릭 이벤트 : ", res);
      });
    } catch (e) {
      console.error("set_prod_click ERROR : ", e);
    }
  };

  return (
    <>
      <Box className="live-more-body-box-pc">
        <Box className="prod-list-header">
          <Box className="prod-list-header-title">
            <img src="/images/modal-shoplist.png" style={{ width: "20px", marginRight: "7px", marginTop: "2px" }} alt="" /> 판매상품{" "}
            <span>{prodList.length}</span>
          </Box>
        </Box>
        <Box sx={{ width: "100%", mt: 1 }} className={props.videoMode === 1 ? "prod-scroll-pc" : ""}>
          <nav aria-label="Set Resolution">
            <div className="prod-list">
              {/* 상담신청 배너 */}
              {props.cnslImgPath !== "" && props.cnslImgPath !== null && props.cnslImgPath !== undefined && (
                <Box
                  className="prod-item-box"
                  onClick={() => {
                    if (props.cnslPageUrl !== "") {
                      window.open(props.cnslPageUrl, "_blank");
                    }
                  }}
                  sx={{ overflow: "hidden" }}
                >
                  <Box className="prod-item-info">
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <CardMedia
                        component="img"
                        image={props.cnslImgPath}
                        sx={{ height: "70px", objectFit: "cover", borderRadius: "7px" }}
                      />
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              )}
              {/* 이미지 배너 */}
              {props.bnrImgPath !== "" && props.bnrImgPath !== null && props.bnrImgPath !== undefined && (
                <Box
                  className="prod-item-box"
                  onClick={() => {
                    if (props.bnrPageUrl !== "") {
                      window.open(props.bnrPageUrl, "_blank");
                    }
                  }}
                  sx={{ overflow: "hidden" }}
                >
                  <Box className="prod-item-info">
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <CardMedia
                        component="img"
                        image={props.bnrImgPath}
                        sx={{ height: "70px", objectFit: "cover", borderRadius: "7px" }}
                      />
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              )}
              {/* 상품목록 */}
              {prodList.map((obj: any, index: number) => {
                return (
                  <div key={`prod${index}`}>
                    <Box
                      className="prod-item-box"
                      onClick={() => clickProd(obj)} // 품절이어도 상품 클릭은 됨
                    >
                      <Box className="prod-item-info" sx={{ filter: obj.soldout ? "opacity(0.5)" : "none" }}>
                        <Box className="prod-image-box">
                          <img src={obj.thumbImageUrl} alt="" />
                          {props.liveProducts.indexOf(obj.productId) > -1 ? (
                            <>
                              <div className="live-product-now">NOW</div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Box>
                        <Box className="prod-desc-box">
                          <Box className="prod-desc-title-box">
                            <div className="prod-desc-title-2line">{obj.name}</div>
                          </Box>
                          <div>
                            {obj.soldout ? (
                              "품절"
                            ) : obj.consumPrc === obj.salePrice ? (
                              <span className="sale-price">{cUtils.numericComma(obj.salePrice) + "원"}</span>
                            ) : (
                              <Stack direction={"column"} spacing={-0.7} mt={0.3}>
                                {obj.consumPrc !== undefined && obj.consumPrc !== null && (
                                  <span className="original-price line-through color-lightgray">{`${cUtils.numericComma(
                                    obj.consumPrc
                                  )}원`}</span>
                                )}
                                <Stack direction="row" display="flex" alignItems="center" spacing={0.5}>
                                  {obj.salePer !== undefined && obj.salePer !== null && obj.salePer > 0 && (
                                    <span className="color-red sale-price">{obj.salePer + "%"}</span>
                                  )}
                                  <span className="sale-price">{`${cUtils.numericComma(obj.salePrice)}원`}</span>
                                </Stack>
                              </Stack>
                            )}
                          </div>
                        </Box>
                      </Box>
                      <Divider light />
                    </Box>
                  </div>
                );
              })}
            </div>
          </nav>
        </Box>
      </Box>
    </>
  );
};

export default LiveProductPc;
